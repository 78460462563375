import ApiService from "@/core/services/api.service";

// action types
export const FETCH_MEMBER_GROUPS_LIST = "fetchMemberGroupsList";
export const UPDATE_MEMBER_GROUP_DATA = "updateMemberGroupsData";
export const CREATE_MEMBER_GROUP_DATA = "createMemberGroupsData";
export const DELETE_MEMBER_GROUP = "deleteMemberGroups";
export const SET_ERROR = "setError";

// mutation types
export const SET_MEMBER_GROUPS_LIST = "setMemberGroupsList";

const state = {
    member_groups_list: {},
    errors: null,
    calling: {}
};

const getters = {
    memberGroupsList(state) {
        return state.member_groups_list;
    },
};

const actions = {
    [FETCH_MEMBER_GROUPS_LIST](context, filters) {
        return new Promise(resolve => {
            ApiService.setHeader();
            ApiService.post("/api/admin/v1/member_groups/search", filters)
                .then(({data}) => {
                    context.commit(SET_MEMBER_GROUPS_LIST, data);
                    resolve(data);
                })
                .catch(() => {
                });
        });
        // context.commit(SET_USERS_LIST, payload);
    },
    [UPDATE_MEMBER_GROUP_DATA](context, data) {
        ApiService.setHeader();
        ApiService.put("/api/admin/v1/member_groups/" + data.id, data)
            .then(() => {
            })
            .catch(() => {
            });
    },
    [CREATE_MEMBER_GROUP_DATA](context, data) {
        ApiService.setHeader();
        ApiService.post("/api/admin/v1/member_groups/", data)
            .then(() => {
            })
            .catch(err => {
                context.commit(SET_ERROR, err);
            });
    },
    [DELETE_MEMBER_GROUP](context, data) {
        ApiService.setHeader();
        ApiService.delete("/api/admin/v1/member_groups/" + data.id, data)
            .then(() => {
            })
            .catch(err => {
                context.commit(SET_ERROR, err);
            }
            );
    }
};

const mutations = {
    [SET_MEMBER_GROUPS_LIST](state, member_groups_list) {
        state.member_groups_list = member_groups_list;
    },
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
