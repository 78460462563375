import ApiService from "@/core/services/api.service";

// action types
export const FETCH_AVAILABILITIES_LIST = "fetchAvailabilitysList";
export const FETCH_AVAILABILITIES_CHECK = "fetchAvailabilitysListCheck";
export const GET_AVAILABILITY_BY_ID = "getAvailabilityById";
export const SET_ERROR = "setError";

// mutation types
export const SET_AVAILABILITIES_LIST = "setAvailabilitysList";
export const SET_AVAILABILITY_IN_USE = "setAvailabilitysInUse";
export const SET_AVAILABILITIES_LOADING = "setAvailabilitysLoading";
export const SET_AVAILABILITY_SAVE_LOADING = "setSaveAvailabilityLoading";
export const SET_AVAILABILITIES_IN_CHECK = "setAvailabilitysInCheck";

const state = {
  availabilities_list: {},
  availability_in_use: {},
  availability_in_check: {},
  errors: null,
  calling: {},
  loadingListAvailabilitys: false,
  loadingSaveAvailability: false,
};

const getters = {
  availabilitiesList(state) {
    return state.availabilities_list;
  },
  availabilityInUse(state) {
    return state.availability_in_use;
  },
  availabilityInCheck(state) {
    return state.availability_in_check.data;
  },
  availabilityLoading(state) {
    return state.loadingListAvailabilitys;
  },
  availabilitySaveLoading(state) {
    return state.loadingSaveAvailability;
  },
  getAvailabilityApiErrors(state) {
    return state.errors;
  },
};

const actions = {
  [GET_AVAILABILITY_BY_ID](context, id) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.get("/guest/citizen/v1/events/availabilities/" + id)
        .then(({ data }) => {
          context.commit(SET_AVAILABILITY_IN_USE, data);
          resolve(data);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [FETCH_AVAILABILITIES_LIST](context, filters) {
    context.commit(SET_AVAILABILITIES_LOADING, true);
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/guest/citizen/v1/events/search", filters)
        .then(({ data }) => {
          context.commit(SET_AVAILABILITIES_LIST, data);
          resolve(data);
          context.commit(SET_AVAILABILITIES_LOADING, false);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [FETCH_AVAILABILITIES_CHECK](context, filters) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/guest/citizen/v1/events/search", filters)
        .then(({ data }) => {
          context.commit(SET_AVAILABILITIES_IN_CHECK, data);
          resolve(data);
        })
        .catch(() => {});
    });
  },
};

const mutations = {
  [SET_AVAILABILITIES_LIST](state, availabilities_list) {
    state.availabilities_list = availabilities_list;
  },
  [SET_AVAILABILITY_IN_USE](state, availability_in_use) {
    state.availability_in_use = availability_in_use;
  },
  [SET_AVAILABILITIES_IN_CHECK](state, availability_in_check) {
    state.availability_in_check = availability_in_check;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AVAILABILITIES_LOADING](state, value) {
    state.loadingListAvailabilitys = value;
  },
  [SET_AVAILABILITY_SAVE_LOADING](state, value) {
    state.loadingSaveAvailability = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
