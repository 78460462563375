import ApiService from "@/core/services/api.service";

// action types
export const FETCH_CITIES_LIST = "fetchCitiesList";
export const FETCH_CITIES_LIST_BIRTH = "fetchCitiesListBirth";
export const FETCH_CITIES_LIST_RESIDENCE = "fetchCitiesListResidence";
export const FETCH_CITIES_LIST_DOMICILE = "fetchCitiesListDomicile";
export const GET_CITY_BY_ID = "getCityById";
export const GET_RESIDENT_CITY_BY_ID = "getResidentCityById";
export const GET_BIRTH_CITY_BY_ID = "getBirthCityById";
export const UPDATE_CITY_DATA = "updateCitiesData";
export const CREATE_CITY_DATA = "createCitiesData";
export const DELETE_CITY = "deleteCities";
export const SET_ERROR = "setError";
export const RESET_CITIES_LIST = "resetCitiesList";
export const RESET_CITIES_BIRTH_LIST = "resetCitiesBirthList";
export const RESET_CITIES_RESIDENCE_LIST = "resetCitiesResidenceList";
export const RESET_CITIES_DOMICILE_LIST = "resetCitiesDomicileList";
// mutation types
export const SET_CITIES_LIST = "setCitiesList";
export const SET_CITIES_LIST_BIRTH = "setCitiesListBirth";
export const SET_CITIES_LIST_RESIDENCE = "setCitiesListResidence";
export const SET_CITIES_LIST_DOMICILE = "setCitiesListDomicile";
export const SET_CITY_IN_USE = "setCityInUse";
export const SET_RESIDENT_CITY_IN_USE = "setResidentCityInUse";
export const SET_BIRTH_CITY_IN_USE = "setBirthCityInUse";

const state = {
  cities_list: {},
  cities_list_birth: {},
  cities_list_residence: {},
  cities_list_domicile: {},
  city_in_use: {},
  residence_city_in_use: {},
  birth_city_in_use: {},
  errors: null,
  calling: {},
  loadingSearch: false
};

const getters = {
  citiesList(state) {
    return state.cities_list.data;
  },
  citiesListBirth(state) {
    return state.cities_list_birth.data;
  },
  citiesListResidence(state) {
    return state.cities_list_residence.data;
  },
  citiesListDomicile(state) {
    return state.cities_list_domicile.data;
  },
  cityInUse(state) {
    return state.city_in_use.data;
  },
  residenceCityInUse(state) {
    return state.residence_city_in_use.data;
  },
  birthCityInUse(state) {
    return state.birth_city_in_use.data;
  },
  getLoadingState(state) {
    return state.loadingSearch;
  }
};

const actions = {
  [GET_CITY_BY_ID](context, id) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.get("/guest/citizen/v1/localities/cities/" + id)
          .then(({data}) => {
            context.commit(SET_CITY_IN_USE, data);
            resolve(data);
          })
          .catch(() => {
          });
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [GET_RESIDENT_CITY_BY_ID](context, id) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.get("/guest/citizen/v1/localities/cities/" + id)
        .then(({ data }) => {
          context.commit(SET_RESIDENT_CITY_IN_USE, data);
          resolve(data);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [GET_BIRTH_CITY_BY_ID](context, id) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.get("/guest/citizen/v1/localities/cities/" + id)
        .then(({ data }) => {
          context.commit(SET_BIRTH_CITY_IN_USE, data);
          resolve(data);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [FETCH_CITIES_LIST](context, filters) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/guest/citizen/v1/localities/cities/search", filters)
        .then(({ data }) => {
          context.commit(SET_CITIES_LIST, data);
          resolve(data);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [FETCH_CITIES_LIST_BIRTH](context, filters) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/guest/citizen/v1/localities/cities/search", filters)
        .then(({ data }) => {
          context.commit(SET_CITIES_LIST_BIRTH, data);
          resolve(data);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [FETCH_CITIES_LIST_RESIDENCE](context, filters) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/guest/citizen/v1/localities/cities/search", filters)
        .then(({ data }) => {
          context.commit(SET_CITIES_LIST_RESIDENCE, data);
          resolve(data);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [FETCH_CITIES_LIST_DOMICILE](context, filters) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/guest/citizen/v1/localities/cities/search", filters)
        .then(({ data }) => {
          context.commit(SET_CITIES_LIST_DOMICILE, data);
          resolve(data);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [UPDATE_CITY_DATA](context, data) {
    ApiService.setHeader();
    ApiService.put("/guest/citizen/v1/localities/cities/" + data.id, data)
      .then(() => {
      })
      .catch(() => {});
  },
  [CREATE_CITY_DATA](context, data) {
    ApiService.setHeader();
    ApiService.post("/guest/citizen/v1/localities/cities/", data)
      .then(() => {})
      .catch((err) => {
        context.commit(SET_ERROR, err);
      });
  },
  [DELETE_CITY](context, data) {
    ApiService.setHeader();
    ApiService.delete("/guest/citizen/v1/localities/cities/" + data.id, data)
      .then(() => {
      })
      .catch((err) => {
        context.commit(SET_ERROR, err);
      });
  },
  [RESET_CITIES_LIST](context) {
    context.commit(SET_CITIES_LIST, {});
  },
  [RESET_CITIES_BIRTH_LIST](context) {
    context.commit(SET_CITIES_LIST_BIRTH, {});
  },
  [RESET_CITIES_RESIDENCE_LIST](context) {
    context.commit(SET_CITIES_LIST_RESIDENCE, {});
  },
  [RESET_CITIES_DOMICILE_LIST](context) {
    context.commit(SET_CITIES_LIST_DOMICILE, {});
  }
};

const mutations = {
  [SET_CITIES_LIST](state, cities_list) {
    state.cities_list = cities_list;
  },
  [SET_CITIES_LIST_BIRTH](state, cities_list) {
    state.cities_list_birth = cities_list;
  },
  [SET_CITIES_LIST_RESIDENCE](state, cities_list) {
    state.cities_list_residence = cities_list;
  },
  [SET_CITIES_LIST_DOMICILE](state, cities_list) {
    state.cities_list_domicile = cities_list;
  },
  [SET_CITY_IN_USE](state, cities_in_use) {
    state.city_in_use = cities_in_use;
  },
  [SET_RESIDENT_CITY_IN_USE](state, cities_in_use) {
    state.residence_city_in_use = cities_in_use;
  },
  [SET_BIRTH_CITY_IN_USE](state, cities_in_use) {
    state.birth_city_in_use = cities_in_use;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
