import ApiService from "@/core/services/api.service";

// action types
export const FETCH_REGIONS_LIST = "fetchRegionsList";
export const FETCH_REGIONS_LIST_BIRTH = "fetchRegionsListBirth";
export const FETCH_REGIONS_LIST_RESIDENCE = "fetchRegionsListResidence";
export const FETCH_REGIONS_LIST_DOMICILE = "fetchRegionsListDomicile";
export const GET_REGION_BY_ID = "getRegionById";
export const UPDATE_REGION_DATA = "updateRegionsData";
export const CREATE_REGION_DATA = "createRegionsData";
export const DELETE_REGION = "deleteRegions";
export const SET_ERROR = "setError";
export const RESET_REGIONS_LIST = "resetRegionsList";
export const RESET_REGIONS_BIRTH_LIST = "resetRegionsBirthList";
export const RESET_REGIONS_RESIDENCE_LIST = "resetRegionsResidenceList";
export const RESET_REGIONS_DOMICLE_LIST = "resetRegionsDomicileList";

// mutation types
export const SET_REGIONS_LIST = "setRegionsList";
export const SET_REGIONS_LIST_BIRTH = "setRegionsListBirth";
export const SET_REGIONS_LIST_RESIDENCE = "setRegionsListResidence";
export const SET_REGIONS_LIST_DOMICILE = "setRegionsListDomicile";
export const SET_REGION_IN_USE = "setRegionInUse";

const state = {
  regions_list: {},
  regions_list_birth: {},
  regions_list_residence: {},
  regions_list_domicile: {},
  region_in_use: {},
  errors: null,
  calling: {},
};

const getters = {
  regionsList(state) {
    return state.regions_list.data;
  },
  regionsListBirth(state) {
    return state.regions_list_birth.data;
  },
  regionsListResidence(state) {
    return state.regions_list_residence.data;
  },
  regionsListDomicile(state) {
    return state.regions_list_domicile.data;
  },
  regionInUse(state) {
    return state.region_in_use.data;
  },
};

const actions = {
  [GET_REGION_BY_ID](context, id) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.get("/guest/citizen/v1/localities/regions/" + id)
        .then(({ data }) => {
          context.commit(SET_REGION_IN_USE, data);
          resolve(data);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [FETCH_REGIONS_LIST](context, filters) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/guest/citizen/v1/localities/regions/search", filters)
        .then(({ data }) => {
          context.commit(SET_REGIONS_LIST, data);
          context.commit(SET_REGIONS_LIST_BIRTH, data);
          context.commit(SET_REGIONS_LIST_RESIDENCE, data);
          context.commit(SET_REGIONS_LIST_DOMICILE, data);
          resolve(data);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [FETCH_REGIONS_LIST_BIRTH](context, filters) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/guest/citizen/v1/localities/regions/search", filters)
        .then(({ data }) => {
          context.commit(SET_REGIONS_LIST_BIRTH, data);
          resolve(data);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [FETCH_REGIONS_LIST_RESIDENCE](context, filters) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/guest/citizen/v1/localities/regions/search", filters)
        .then(({ data }) => {
          context.commit(SET_REGIONS_LIST_RESIDENCE, data);
          resolve(data);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [FETCH_REGIONS_LIST_DOMICILE](context, filters) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/guest/citizen/v1/localities/regions/search", filters)
        .then(({ data }) => {
          context.commit(SET_REGIONS_LIST_DOMICILE, data);
          resolve(data);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [UPDATE_REGION_DATA](context, data) {
    ApiService.setHeader();
    ApiService.put("/guest/citizen/v1/localities/regions/" + data.id, data)
      .then(() => {
      })
      .catch(() => {});
  },
  [CREATE_REGION_DATA](context, data) {
    ApiService.setHeader();
    ApiService.post("/guest/citizen/v1/localities/regions/", data)
      .then(() => {
      })
      .catch((err) => {
        context.commit(SET_ERROR, err);
      });
  },
  [DELETE_REGION](context, data) {
    ApiService.setHeader();
    ApiService.delete("/guest/citizen/v1/localities/regions/" + data.id, data)
      .then(() => {
      })
      .catch((err) => {
        context.commit(SET_ERROR, err);
      });
  },
  [RESET_REGIONS_LIST](context) {
    context.commit(SET_REGIONS_LIST, {});
  },
  [RESET_REGIONS_BIRTH_LIST](context) {
    context.commit(SET_REGIONS_LIST_BIRTH, {});
  },
  [RESET_REGIONS_RESIDENCE_LIST](context) {
    context.commit(SET_REGIONS_LIST_RESIDENCE, {});
  },
  [RESET_REGIONS_DOMICLE_LIST](context) {
    context.commit(SET_REGIONS_LIST_DOMICILE, {});
  },
};

const mutations = {
  [SET_REGIONS_LIST](state, regions_list) {
    state.regions_list = regions_list;
  },
  [SET_REGIONS_LIST_BIRTH](state, regions_list) {
    state.regions_list_birth = regions_list;
  },
  [SET_REGIONS_LIST_RESIDENCE](state, regions_list) {
    state.regions_list_residence = regions_list;
  },
  [SET_REGIONS_LIST_DOMICILE](state, regions_list) {
    state.regions_list_domicile = regions_list;
  },
  [SET_REGION_IN_USE](state, regions_in_use) {
    state.region_in_use = regions_in_use;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
