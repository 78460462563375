import objectPath from "object-path";
import merge from "deepmerge";
import config from "@/core/config/layout.config.json";
import aside_menu from "@/core/config/menu.json"
// action types
export const SET_LAYOUT_CONFIG = "setLayoutConfig";
export const RESET_LAYOUT_CONFIG = "resetLayoutConfig";
export const OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig";
export const OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig";
export const ASIDE_MENU_CONFIG = "aSideMenuConfig";
export const SET_CUSTOM_CONFIG = "customConfig";
export const READ_CUSTOM_CONFIG = "readCustomConfig";
// mutation types

export default {
  state: {
    config: config,
    initial: config,
    aside_menu: aside_menu,
    custom_config: {
      footer: {
        owner: "",
        text: "",
        child:{
          home: "",
          privacy: "",
          contact_us: "#",
          email_pec: ""
        },
        copyright: "V.Micale @ virgi.micaleAtgmail.com"
      },
      header: {
        logo: "",
        title: ""
      }
    }
  },
  getters: {
    /**
     * Get config from layout config
     * @param state
     * @returns {function(path, defaultValue): *}
     */
    layoutConfig: state => (path, defaultValue) => {
      return objectPath.get(state.config, path, defaultValue);
    },
    asideMenu(state) {
      return state.aside_menu;
    },
    customConfig(state){
      return state.custom_config;
    }
  },
  actions: {
    [READ_CUSTOM_CONFIG](state) {
      fetch(process.env.BASE_URL + "config.json")
          .then((response) => response.json())
          .then((config) => {
            state.commit(SET_CUSTOM_CONFIG, config)
          })
    },
    /**
     * Set and replace the whole config
     * @param state
     * @param payload
     */
    [SET_LAYOUT_CONFIG](state, payload) {
      state.commit(SET_LAYOUT_CONFIG, payload);
    },

    /**
     * Reset the config at the initial state
     * @param state
     */
    [RESET_LAYOUT_CONFIG](state) {
      state.commit(RESET_LAYOUT_CONFIG);
    },

    /**
     * Reset the config using saved config in the cache by the layout builder
     * @param state
     */
    [OVERRIDE_LAYOUT_CONFIG](state) {
      state.commit(OVERRIDE_LAYOUT_CONFIG);
    },

    [ASIDE_MENU_CONFIG](state) {
      state.commit(ASIDE_MENU_CONFIG);
    },

    /**
     * Override config by page level
     * @param state
     * @param payload
     */
    [OVERRIDE_PAGE_LAYOUT_CONFIG](state, payload) {
      state.commit(OVERRIDE_PAGE_LAYOUT_CONFIG, payload);
    }
  },
  mutations: {
    [SET_CUSTOM_CONFIG](state, payload) {
      state.custom_config = payload;
    },
    [SET_LAYOUT_CONFIG](state, payload) {
      state.config = payload;
    },
    [RESET_LAYOUT_CONFIG](state) {
      state.config = Object.assign({}, state.initial);
    },
    [OVERRIDE_LAYOUT_CONFIG](state) {
      state.config = state.initial = Object.assign(
        {},
        state.initial,
        JSON.parse(localStorage.getItem("config"))
      );
    },
    [ASIDE_MENU_CONFIG](state) {
      state.config = state.initial = Object.assign(
        {},
        state.initial,
        JSON.parse(localStorage.getItem("aside_menu"))
      );
    },
    [OVERRIDE_PAGE_LAYOUT_CONFIG](state, payload) {
      state.config = merge(state.config, payload);
    }
  }
};
