import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          name: "index",
          path: "/",
          redirect: "/events",
          component: () => import("@/view/pages/base/Base"),
          children: [
            {
              path: "/list",
              name: "list_event_page",
              component: () => import("@/view/pages/reservations/List")
            },
            {
              path: "/login",
              name: "login_page",
              component: () => import("@/view/pages/login/Login")
            },
            {
              path: "/reset/:token",
              name: "reset_password_page",
              component: () => import("@/view/pages/reset/Reset")
            },
            {
              path: "/events",
              name: "events_dashboard",
              component: () => import("@/view/pages/events/New")
            },
            {
              path: "/resume/:code",
              name: "resume_reservation",
              component: () => import("@/view/pages/reservations/Resume")
            },
            {
              path: "/resume/:code/:r",
              name: "resume_reservation",
              component: () => import("@/view/pages/reservations/Resume")
            }
          ]
        }
      ]
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    }
  ]
});
