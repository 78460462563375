import ApiService from "@/core/services/api.service";

// action types
export const FETCH_CITIZENS_LIST = "fetchCitizensList";
export const FETCH_CITIZENS_CHECK = "fetchCitizensListCheck";
export const GET_CITIZEN_BY_ID = "getCitizenById";
export const UPDATE_CITIZEN_DATA = "updateCitizensData";
export const CREATE_CITIZEN_DATA = "createCitizensData";
export const DELETE_CITIZEN = "deleteCitizens";
export const SET_CITIZEN_ERROR = "setCitizenError";
export const RESET_CITIZEN_IN_CHECK = "resetCitizenInCheck";
export const FETCH_FISCAL_CODE_CHECK = "fetchFiscalCodeCheck";

// mutation types
export const SET_CITIZENS_LIST = "setCitizensList";
export const SET_CITIZEN_IN_USE = "setCitizensInUse";
export const SET_CITIZENS_LOADING = "setCitizensLoading";
export const SET_CITIZEN_SAVE_LOADING = "setSaveCitizenLoading";
export const SET_CITIZENS_IN_CHECK = "setCitizensInCheck";
export const SET_CITIZEN_SAVING_SUCCESS = "setCitizenSavingSuccess";
export const SET_CITIZEN_SAVED = "setCitizenSaved";
export const SET_FISCAL_CODE_CHECKED = "setFiscalCodeChecked";

const state = {
  citizens_saved: {},
  citizens_list: {},
  citizen_in_use: {},
  citizen_in_check: {},
  errors: null,
  calling: {},
  loadingListCitizens: false,
  loadingSaveCitizen: false,
  savingSuccess: false,
  fiscal_code_checked: false
};

const getters = {
  citizensList(state) {
    return state.citizens_list;
  },
  citizenInUse(state) {
    return state.citizen_in_use.data;
  },
  citizenInCheck(state) {
    return state.citizen_in_check.data;
  },
  citizenLoading(state) {
    return state.loadingListCitizens;
  },
  citizenSaveLoading(state) {
    return state.loadingSaveCitizen;
  },
  getCitizenApiErrors(state) {
    return state.errors;
  },
  getCitizenSavingSuccess(state) {
    return state.savingSuccess;
  },
  getCitizenSaved(state) {
    return state.citizens_saved;
  },
  getFiscalCodeChecked(state) {
    return state.fiscal_code_checked;
  }
};

const actions = {
  [GET_CITIZEN_BY_ID](context, id) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.get("/api/admin/v1/citizens/" + id)
        .then(({ data }) => {
          context.commit(SET_CITIZEN_IN_USE, data);
          resolve(data);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [FETCH_CITIZENS_LIST](context, filters) {
    context.commit(SET_CITIZENS_LOADING, true);
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/api/admin/v1/citizens/search", filters)
        .then(({ data }) => {
          context.commit(SET_CITIZENS_LIST, data);
          resolve(data);
          context.commit(SET_CITIZENS_LOADING, false);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [FETCH_CITIZENS_CHECK](context, filters) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/api/admin/v1/citizens/search", filters)
        .then(({ data }) => {
          context.commit(SET_CITIZENS_IN_CHECK, data);
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [FETCH_FISCAL_CODE_CHECK](context, fiscalCode) {
    return new Promise((resolve) => {
      ApiService.get("/guest/citizen/v1/utils/check_fiscal_code/"+fiscalCode)
        .then(({ data }) => {
          context.commit(SET_FISCAL_CODE_CHECKED, data);
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [UPDATE_CITIZEN_DATA](context, data) {
    ApiService.setHeader();
    context.commit(SET_CITIZEN_SAVE_LOADING, true);
    context.commit(SET_CITIZEN_SAVING_SUCCESS, false);
    ApiService.put("/api/admin/v1/citizens/" + data.id, data)
      .then(() => {
        context.commit(SET_CITIZEN_SAVE_LOADING, false);
        context.commit(SET_CITIZEN_SAVING_SUCCESS, true);
      })
      .catch((err) => {
        context.commit(SET_CITIZEN_SAVE_LOADING, false);
        context.commit(SET_CITIZEN_ERROR, err);
      });
  },
  [RESET_CITIZEN_IN_CHECK](context) {
    context.commit(SET_CITIZENS_IN_CHECK, {});
  },
  [CREATE_CITIZEN_DATA](context, data) {
    ApiService.setHeader();
    context.commit(SET_CITIZEN_SAVE_LOADING, true);
    context.commit(SET_CITIZEN_SAVING_SUCCESS, false);
    ApiService.post("/guest/citizen/v1/utils/creation", data)
      .then((result) => {
        context.commit(SET_CITIZEN_SAVED, result);
        context.commit(SET_CITIZEN_SAVE_LOADING, false);
        context.commit(SET_CITIZEN_SAVING_SUCCESS, true);
      })
      .catch((err) => {
        context.commit(SET_CITIZEN_SAVE_LOADING, false);
        context.commit(SET_CITIZEN_ERROR, err);
      });
  },
  [DELETE_CITIZEN](context, data) {
    ApiService.setHeader();
    ApiService.delete("/api/admin/v1/citizens/" + data.id, data)
      .then(() => {
      })
      .catch((err) => {
        context.commit(SET_CITIZEN_ERROR, err);
      });
  }
};

const mutations = {
  [SET_CITIZENS_LIST](state, citizens_list) {
    state.citizens_list = citizens_list;
  },
  [SET_CITIZEN_IN_USE](state, citizen_in_use) {
    state.citizen_in_use = citizen_in_use;
  },
  [SET_CITIZENS_IN_CHECK](state, citizen_in_check) {
    state.citizen_in_check = citizen_in_check;
  },
  [SET_CITIZEN_ERROR](state, error) {
    state.errors = error;
  },
  [SET_CITIZENS_LOADING](state, value) {
    state.loadingListCitizens = value;
  },
  [SET_CITIZEN_SAVE_LOADING](state, value) {
    state.loadingSaveCitizen = value;
  },
  [SET_CITIZEN_SAVING_SUCCESS](state, value) {
    state.savingSuccess = value;
  },
  [SET_CITIZEN_SAVED](state, value) {
    state.citizens_saved = value.data;
  },
  [SET_FISCAL_CODE_CHECKED](state, value) {
    state.fiscal_code_checked = value.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
//mclvgl80b02f158u
//clesfn80t66f158w
