import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { UPDATE_BASE_INFO } from "@/core/services/store/profile.module";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const USERINFO = "userInfo";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updatePassword";
export const FORGOT_PASSWORD = "forgotPassword";
export const FORGOT_PASSWORD_CHECK_TOKEN = "forgotPasswordCheckToken";
export const FORGOT_PASSWORD_UPDATE_PASSWORD = "forgotPasswordUpdatePassword";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setAuth";
export const SET_USER = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoading";
export const SET_FORGOT_PASSWORD_REQUEST_STATUS = "setForgotRequestStatus";
export const SET_FORGOT_PASSWORD_CHECK_TOKEN_REQUEST_STATUS = "setForgotCheckTokenRequestStatus";
export const SET_UPDATED_STATUS = "setUpdatedStatus";
export const SET_VERIFIED_REQUEST_STATUS = "setVerifiedRequestStatus";

const state = {
  errors: null,
  user_data: {},
  auth: {},
  isAuthenticated: !!JwtService.getToken(),
  loginLoading: false,
  forgot_request_status: null,
  forgot_password_token_request_status: false,
  updated_status: false,
  verified_request: false
};

const getters = {
  currentUser(state) {
    return state.user_data;
  },
  isLoggingLoading(state) {
    return state.loginLoading;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  getLoginError(state) {
    return state.errors;
  },
  getForgotRequestStatus(state) {
    return state.forgot_request_status;
  },
  getForgotPasswordCheckTokenRequestStatus(state) {
    return state.forgot_password_token_request_status;
  },
  getRequestVerifiedStatus(state) {
    return state.verified_request;
  }
};

const mutations = {
  [SET_LOADING](state, value) {
    state.loginLoading = value;
  },
  [SET_VERIFIED_REQUEST_STATUS](state, value) {
    state.verified_request = value;
  },
  [SET_FORGOT_PASSWORD_REQUEST_STATUS](state, value) {
    state.forgot_request_status = value;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, auth) {
    state.isAuthenticated = true;
    state.auth = auth;
    // state.user = auth;
    state.errors = {};
    JwtService.saveToken(state.auth.token);
  },
  [SET_USER](state, user) {
    state.user_data = user;
    state.errors = {};
  },
  [SET_PASSWORD](state, password) {
    state.user_data.password = password;
  },
  [SET_UPDATED_STATUS](state, value) {
    state.updated_status = value;
  },
  [SET_FORGOT_PASSWORD_CHECK_TOKEN_REQUEST_STATUS](state, value) {
    state.forgot_password_token_request_status = value;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.auth = {};
    state.user_data = {};
    state.errors = {};
    JwtService.destroyToken();
    JwtService.destroyRefreshToken();
  }
};

const actions = {
  [LOGIN](context, credentials) {
    // context.commit(PURGE_AUTH);
    return new Promise((resolve) => {
      context.commit(SET_LOADING, true);
      ApiService.setLoginHeader();
      ApiService.post("citizen/login_check", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          context.commit(SET_LOADING, false);
          context.dispatch(USERINFO)

          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
          context.commit(SET_ERROR, true);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      context.commit(SET_VERIFIED_REQUEST_STATUS, true);
      ApiService.setHeader();
      ApiService.get("citizen/verify")
        .then(({ data }) => {
          if (data.code == 200 && data.message == "active") {
            if (context.getters.currentUserBaseInfo.id == undefined) {
              context.dispatch(USERINFO);
            }
            context.commit(SET_VERIFIED_REQUEST_STATUS, false);
            // return data
          }
        })
        .catch(function (error) {
          // Something happened in setting up the request that triggered an Error
          if (error.message != undefined && error.message.includes("401")) {
            context.commit(PURGE_AUTH);
          }
          context.commit(SET_VERIFIED_REQUEST_STATUS, false);
          context.commit(SET_ERROR, error.data);
        });
    } else {
      // context.commit(PURGE_AUTH);
    }
  },
  [USERINFO](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("/api/citizen/v1/citizens/1/current").then(({ data }) => {
        var user_tmp = [];
        var excluded = ["member_group"];
        Object.keys(data).forEach((key) => {
          if (!excluded.includes(key)) {
            user_tmp[key] = data[key];
          }
        });
        context.dispatch(UPDATE_BASE_INFO, user_tmp);
      });
    }
  },

  [UPDATE_PASSWORD](context, payload) {
    return ApiService.put("password", payload).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
  [FORGOT_PASSWORD](context, payload) {
    return ApiService.post("/guest/citizen/v1/utils/forgot_password", payload)
      .then(({ data }) => {
        context.commit(SET_FORGOT_PASSWORD_REQUEST_STATUS, true);
        return data;
      })
      .catch(function () {
        context.commit(SET_FORGOT_PASSWORD_REQUEST_STATUS, false);
      });
  },
  [FORGOT_PASSWORD_CHECK_TOKEN](context, token) {
    return ApiService.get("/guest/citizen/v1/utils/check_token/"+ token)
      .then(({ data }) => {
        if(data == 'success') {
          context.commit(SET_FORGOT_PASSWORD_CHECK_TOKEN_REQUEST_STATUS, true);
        }
      })
      .catch(function () {
        context.commit(SET_FORGOT_PASSWORD_CHECK_TOKEN_REQUEST_STATUS, false);
      });
  },
  [FORGOT_PASSWORD_UPDATE_PASSWORD](context, payload) {
    return ApiService.post("/guest/citizen/v1/utils/update_password_token", payload)
      .then(({ data }) => {
        if(data == 'success') {
          context.commit(SET_UPDATED_STATUS, true);
          context.commit(SET_ERROR, false);
          return true;
        } else {
          context.commit(SET_UPDATED_STATUS, false);
          context.commit(SET_ERROR, true);
          return false;
        }
      })
      .catch(function () {
        context.commit(SET_ERROR, true);
      });
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
};
