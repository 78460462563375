import ApiService from "@/core/services/api.service";

// action types
export const FETCH_STATES_LIST = "fetchStatesList";
export const FETCH_STATES_LIST_BIRTH = "fetchStatesListBirth";
export const FETCH_STATES_LIST_RESIDENCE = "fetchStatesListResidence";
export const FETCH_STATES_LIST_DOMICILE = "fetchStatesListDomicile";
export const FETCH_FULL_STATES_LIST = "fetchFullStatesList";
export const GET_STATE_BY_ID = "getStateById";
export const UPDATE_STATE_DATA = "updateStatesData";
export const CREATE_STATE_DATA = "createStatesData";
export const DELETE_STATE = "deleteStates";
export const SET_ERROR = "setError";
export const RESET_STATES_LIST = "resetStateList";
export const RESET_STATES_BIRTH_LIST = "resetStateBirthList";
export const RESET_STATES_RESIDENCE_LIST = "resetStateResidenceList";
export const RESET_STATES_DOMICILE_LIST = "resetStateDomicileList";

// mutation types
export const SET_STATES_LIST = "setStatesList";
export const SET_STATES_LIST_BIRTH = "setStatesListBirth";
export const SET_STATES_LIST_RESIDENCE = "setStatesListResidence";
export const SET_STATES_LIST_DOMICLE = "setStatesListDomicile";
export const SET_FULL_STATES_LIST = "setFullStatesList";
export const SET_STATE_IN_USE = "setStateInUse";

const state = {
  full_states_list: {},
  states_list: {},
  states_list_birth: {},
  states_list_residence: {},
  states_list_domicile: {},
  state_in_use: {},
  errors: null,
  calling: {},
};

const getters = {
  statesList(state) {
    return state.states_list.data;
  },
  statesListBirth(state) {
    return state.states_list_birth.data;
  },
  statesListResidence(state) {
    return state.states_list_residence.data;
  },
  statesListDomicile(state) {
    return state.states_list_domicile.data;
  },
  stateInUse(state) {
    return state.state_in_use.data;
  },
  fullStateList(state) {
    return state.full_states_list.data;
  },
};

const actions = {
  [GET_STATE_BY_ID](context, id) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.get("/guest/citizen/v1/localities/states/" + id)
        .then(({ data }) => {
          context.commit(SET_STATE_IN_USE, data);
          resolve(data);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [FETCH_STATES_LIST](context, filters) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/guest/citizen/v1/localities/states/search", filters)
        .then(({ data }) => {
          context.commit(SET_STATES_LIST, data);
          resolve(data);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [FETCH_STATES_LIST_BIRTH](context, filters) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/guest/citizen/v1/localities/states/search", filters)
        .then(({ data }) => {
          context.commit(SET_STATES_LIST_BIRTH, data);
          resolve(data);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [FETCH_STATES_LIST_RESIDENCE](context, filters) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/guest/citizen/v1/localities/states/search", filters)
        .then(({ data }) => {
          context.commit(SET_STATES_LIST_RESIDENCE, data);
          resolve(data);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [FETCH_STATES_LIST_DOMICILE](context, filters) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/guest/citizen/v1/localities/states/search", filters)
        .then(({ data }) => {
          context.commit(SET_STATES_LIST_DOMICLE, data);
          resolve(data);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [FETCH_FULL_STATES_LIST](context, filters) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/guest/citizen/v1/localities/states/search", filters)
        .then(({ data }) => {
          context.commit(SET_FULL_STATES_LIST, data);
          resolve(data);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [RESET_STATES_LIST](context) {
    context.commit(SET_STATES_LIST, {});
  },
  [RESET_STATES_BIRTH_LIST](context) {
    context.commit(SET_STATES_LIST_BIRTH, {});
  },
  [RESET_STATES_RESIDENCE_LIST](context) {
    context.commit(SET_STATES_LIST_RESIDENCE, {});
  },
  [RESET_STATES_DOMICILE_LIST](context) {
    context.commit(SET_STATES_LIST_DOMICLE, {});
  },
  [UPDATE_STATE_DATA](context, data) {
    ApiService.setHeader();
    ApiService.put("/guest/citizen/v1/localities/states/" + data.id, data)
      .then(() => {
      })
      .catch(() => {});
  },
  [CREATE_STATE_DATA](context, data) {
    ApiService.setHeader();
    ApiService.post("/guest/citizen/v1/localities/states/", data)
      .then(() => {
      })
      .catch((err) => {
        context.commit(SET_ERROR, err);
      });
  },
  [DELETE_STATE](context, data) {
    ApiService.setHeader();
    ApiService.delete("/guest/citizen/v1/localities/states/" + data.id, data)
      .then(() => {
      })
      .catch((err) => {
        context.commit(SET_ERROR, err);
      });
  },
};

const mutations = {
  [SET_STATES_LIST](state, states_list) {
    state.states_list = states_list;
  },
  [SET_STATES_LIST_BIRTH](state, states_list) {
    state.states_list_birth = states_list;
  },
  [SET_STATES_LIST_RESIDENCE](state, states_list) {
    state.states_list_residence = states_list;
  },
  [SET_STATES_LIST_DOMICLE](state, states_list) {
    state.states_list_domicile = states_list;
  },
  [SET_FULL_STATES_LIST](state, states_list) {
    state.full_states_list = states_list;
  },
  [SET_STATE_IN_USE](state, states_in_use) {
    state.state_in_use = states_in_use;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
